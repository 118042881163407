import "regenerator-runtime";
import "@fontsource/newsreader/index.css";
import "@fontsource/newsreader/variable-italic.css";
import "./index.scss";
import "highlight.js/styles/github.css";
import(
/* webpackPrefetch: true */
/* webpackChunkName: "setup" */ "./setup").then(({ default: setup }) => setup());
if (module.hot) {
    module.hot.accept();
}
